import React, { useEffect, useState } from "react";
import { Select, Box, useToast, Flex } from "@chakra-ui/react";
import axiosInstance from "../helpers/axiosInstance"; // Import your Axios instance

const CurrentUserOrganization = () => {
  const [currentOrganization, setCurrentOrganization] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  // Fetch the current user's organization
  useEffect(() => {
    const fetchCurrentUserOrganization = async () => {
      try {
        const response = await axiosInstance.get(
          "/org/get_current_organization"
        );
        // console.log("Current Organization:", response.data.organization);
        if (response.data && response.data.organization) {
          setCurrentOrganization(response.data.organization);
        } else {
          setCurrentOrganization("Organization not set");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching current user's organization:", error);
        setLoading(false);
      }
    };

    fetchCurrentUserOrganization();
  }, []);

  // Fetch all organizations
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await axiosInstance.get("/org/get_organizations");
        // console.log("Fetched organizations:", response.data.organizations);
        if (Array.isArray(response.data.organizations)) {
          setOrganizations(response.data.organizations);
        } else {
          console.error("Unexpected API response:", response.data);
          setOrganizations([]);
        }
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };

    fetchOrganizations();
  }, []);

  // Handle organization selection
  const handleOrganizationSelect = async (e) => {
    const selectedOrganization = e.target.value;

    try {
     const response = await axiosInstance.post("/website_settings/update-organization", {
        new_organization: selectedOrganization,
      });
      if (response.status === 200) {
        const keysToRemove = [
          "chat_messages",
          "selectedAreaChat",
          "selectedModuleChat",
          "selectedAreaModuleChat",
          "selectedRole",
          "roles",
          "dropdownValue",
          "editedResponse",
          "selectedEmailId",
          "isEditing",
          "selectedText",
          "draftResponse",
          "modules",
          "selectedThread",
          "domains",
          "uploading",
          "selectedEmailIndex",
          "error",
          "selectedModule",
          "threads",
          "selectedThreadEmails",
          "selectedArea",
          "selectedModuleEmail",
          "user",
          "temperature",
          "model",
          "selectedModel",
          "topP",
          "frequencyPenalty",
          "presencePenalty",
          "maxTokens",
          "MANAGER_ORGANIZATION",
          "modulesUpload",
          "selectedModuleUpload",
          "showInputFieldUpload",
          "currentMinuteUpload",
          "moduleMinutesUpload",
          "selectedMinuteUpload",
          "companyNameUpload",
          "fundNameUpload",
          "corporateSecretaryUpload",
          "depositoryBankUpload",
          "fundAdministratorUpload",
          "fundAliasUpload",
          "endDateUpload",
          "isOutputReceivedUpload",
          "startDateUpload",
          "companyAliasUpload",
          "fundAuditorUpload",
          "investmentManagementEntityUpload",
          "directorsUpload",
          "currentDirectorUpload",
          "showInputDirectorUpload",
          "mainValuesUpload",
          "currentMainValueUpload",
          "showMainValueInputUpload",
          "subValuesUpload",
          "currentSubValueUpload",
          "showSubValueInputUpload",
          "headingsUpload",
          "submittedHeadingsUpload",
          "outputTextUpload",
          "headingOutputsUpload",
          "isUploading",
          "minutesListUpload",
          "setIsLoadingUpload",
          "uploading",
          "selectedRoleChat",
          "deepSearch",
          "minutesData",
          "storageKey",
          "agendaStorageKeys",
          "agendaPointsUpload",
          "successfulGenerations",
          "successfulGenerateAll",
          "loadingGenerateAll",
          "loadingAgendas",
          "showInputFieldMinuteUpload",
          "agendaPoints",
          "modulesDDQ_RFP",
          "selectedModuleDDQ_RFP",
          "selectedRoleDDQ_RFP",
          "selectedMinutesIdDDQ_RFP",
          "agendaPointsDDQ_RFP",
          "successfulGenerateAllDDQ_RFP",
          "successfulGenerationsDDQ_RFP",
          "loadingAgendasDDQ_RFP",
          "loadingGenerateAllDDQ_RFP",
          "currentMinuteUploadDDQ_RFP",
          "minutesListUploadDDQ_RFP",
          "showInputFieldUploadDDQ_RFP",
          "showInputFieldMinuteUploadDDQ_RFP",
          "agendaStorageKeysDDQ_RFP",
          "editedAgendaPointDDQ_RFP",
          "selectedMinuteDDQ_RFP",
          "emailsDDQ_RFP",
          "selectedRoleEmail",
          "selectedDropdowns",
          "selectedTabIndex",
          "fundsListUploadFund",
          "selectedFund",
          "agendaPointsFund",
          "showInputFieldUploadFund",
          "currentFundUploadFund",
          "campaigns",
          "sequences",
          "linked",
        ];

        keysToRemove.forEach((key) => localStorage.removeItem(key));

        setCurrentOrganization(selectedOrganization);
        // Reload the page after a successful response
        window.location.reload();
        toast({
          title: "Organization Updated",
          description: "Your organization has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating organization:", error);
      toast({
        title: "Error",
        description: "Failed to update organization. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Flex justifyContent="flex-end">
          <Select
            w="150px"
            size="sm"
            borderRadius="lg"
            placeholder="Select an organization"
            value={currentOrganization}
            onChange={handleOrganizationSelect}
          >
            {organizations.map((org, index) => (
              <option key={index} value={org}>
                {org}
              </option>
            ))}
          </Select>
        </Flex>
      )}
    </Box>
  );
};

export default CurrentUserOrganization;
