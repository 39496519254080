import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Divider,
  Text,
  Button,
  useToast,
  Input,
  IconButton,
  useColorMode,
  HStack,
} from "@chakra-ui/react";
import { EditIcon, MinusIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  sendAgendaPointsToBackend,
  handlePreview,
  fetchFileDetails,
} from "../../api/DDQ_RFP/fileApi";
import DynamicInput from "../../components/common/DynamicInput";
import usePersistedState from "../../components/usePersistedState";
import { logout } from "../../auth";
import FileDownload from "../../components/DDQ_RFP/FileDownload";
import FileSent from "./FileSent";
import DropdownWithCheckboxes from "./Dropdown";
import axiosInstance from "../../helpers/axiosInstance";
import FileDownloadButton from "./FileDownloadButton";

const Questions = ({ setOutputText, setModalHeader, selectedMinutesId }) => {
  // const selectedMinutesId = "653d855cd47847b445ab1604"; //remove this line and uncomment the above uncommented line
  const { colorMode } = useColorMode();
  const documentId = 1;
  const toast = useToast();
  const [agendaPoints, setAgendaPoints] = usePersistedState(
    "agendaPointsDDQ_RFP",
    []
  );
  const [editing, setEditing] = useState(null);
  const [successfulGenerateAll, setSuccessfulGenerateAll] = usePersistedState(
    "successfulGenerateAllDDQ_RFP",
    {}
  );
  const [successfulGenerations, setSuccessfulGenerations] = usePersistedState(
    "successfulGenerationsDDQ_RFP",
    {}
  );
  const [loadingAgendas, setLoadingAgendas] = usePersistedState(
    "successfulGenerationsDDQ_RFP",
    {}
  );
  const [loadingGenerateAll, setLoadingGenerateAll] = usePersistedState(
    "loadingGenerateAllDDQ_RFP",
    {}
  );
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingAll, setIsGeneratingAll] = useState(false);
  const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY").replace(/"/g, "");

  const handleError = (title, description) => {
    toast({
      title,
      description,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  // Initialize the selectedDropdowns state from local storage
  const [selectedDropdowns, setSelectedDropdowns] = useState(() => {
    const savedSelections = localStorage.getItem("selectedDropdowns");
    return savedSelections ? JSON.parse(savedSelections) : {};
  });

  // Use useEffect to update local storage whenever selectedDropdowns changes
  useEffect(() => {
    localStorage.setItem(
      "selectedDropdowns",
      JSON.stringify(selectedDropdowns)
    );
  }, [selectedDropdowns]);

  // Handler for dropdown selection - modified for multiple selections
  const handleDropdownSelect = (questionIndex, selectedQuestions) => {
    setSelectedDropdowns((prev) => ({
      ...prev,
      [`${selectedMinutesId}-${questionIndex}`]: selectedQuestions,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const minuteDetails = await fetchFileDetails(
          selectedMinutesId,
          handleError
        );
        let fetchedAgendaPoints;

        if (minuteDetails && minuteDetails.question_list) {
          fetchedAgendaPoints = Object.values(minuteDetails.question_list);
        } else {
          fetchedAgendaPoints = [];
        }

        // Copy the original data to updatedAgendaPoints
        let updatedAgendaPoints = [...fetchedAgendaPoints];

        const storedHeadingKeys = JSON.parse(
          localStorage.getItem("agendaStorageKeysDDQ_RFP") || "[]"
        );

        storedHeadingKeys.forEach((key) => {
          const match = key.match(
            new RegExp(`^editedAgendaPointDDQ_RFP-${selectedMinutesId}-(\\d+)$`)
          );
          if (match) {
            const index = parseInt(match[1], 10);
            const storedAgendaPoint = JSON.parse(localStorage.getItem(key));

            // Ensure index is within bounds to prevent overriding fetched data
            if (index < updatedAgendaPoints.length) {
              updatedAgendaPoints[index] = storedAgendaPoint;
            }
          }
        });

        setAgendaPoints(updatedAgendaPoints);
      } catch (error) {
        handleError("Error fetching DDQ/RFP details", error.message);
      }
    };

    fetchData();
  }, [selectedMinutesId, token]);

  const saveAgenda = (index) => {
    const agenda = agendaPoints[index];
    const storedHeadingKey = `editedAgendaPointDDQ_RFP-${selectedMinutesId}-${index}`;

    localStorage.setItem(storedHeadingKey, JSON.stringify(agenda));

    // Add this storedHeadingKey to agendaStorageKeysDDQ_RFP array in local storage
    const storedHeadingKeys = JSON.parse(
      localStorage.getItem("agendaStorageKeysDDQ_RFP") || "[]"
    );
    if (!storedHeadingKeys.includes(storedHeadingKey)) {
      storedHeadingKeys.push(storedHeadingKey);
      //console.log(`Added key to agendaStorageKeysDDQ_RFP: ${storedHeadingKey}`);
      localStorage.setItem(
        "agendaStorageKeysDDQ_RFP",
        JSON.stringify(storedHeadingKeys)
      );
      //console.log(
      // "Agenda Storage Keys After Save:",
      //  JSON.parse(localStorage.getItem("agendaStorageKeysDDQ_RFP"))
      //);
    }
  };

  const handleInputChange = (index, type, value, subIndex = null) => {
    const updatedAgendaPoints = [...agendaPoints];

    if (type === "question") {
      updatedAgendaPoints[index].question = value;
    }

    setAgendaPoints(updatedAgendaPoints);
    saveAgenda(index);
  };

  const handleAddAgendaPoint = (value) => {
    if (!selectedMinutesId) {
      alert("Please select a minute first!");
      return;
    }

    const newAgendaPoint = {
      question: value,
    };

    // Append the new agenda point to the end of the agendaPoints array
    const updatedAgendaPoints = [
      ...agendaPoints, // All existing agenda points
      newAgendaPoint, // New agenda point added at the end
    ];

    // Save the new agenda point
    const newIndex = updatedAgendaPoints.length - 1; // The new agenda point is now the last item in the array
    saveAgendaUsingValue(newIndex, newAgendaPoint); // Use a modified version of saveAgenda

    // Finally, update the state
    setAgendaPoints(updatedAgendaPoints);
  };

  const saveAgendaUsingValue = (index, agendaToSave) => {
    const storedHeadingKey = `editedAgendaPointDDQ_RFP-${selectedMinutesId}-${index}`;

    localStorage.setItem(storedHeadingKey, JSON.stringify(agendaToSave));

    // Add this storedHeadingKey to agendaStorageKeysDDQ_RFP array in local storage
    const storedHeadingKeys = JSON.parse(
      localStorage.getItem("agendaStorageKeysDDQ_RFP") || "[]"
    );
    if (!storedHeadingKeys.includes(storedHeadingKey)) {
      storedHeadingKeys.push(storedHeadingKey);
      localStorage.setItem(
        "agendaStorageKeysDDQ_RFP",
        JSON.stringify(storedHeadingKeys)
      );
    }
  };

  const deleteHeading = async (index) => {
    // Define the payload for the API call
    const payload = {
      str_id: selectedMinutesId,
      index: index,
    };
    console.log("delete Question", payload);
    // Make a POST request to the backend to delete the agenda point using Axios
    try {
      const response = await axiosInstance.post(
        "/ddq-rfp/delete-minute-by-index",
        payload
      );

      // If the backend confirms deletion successfully
      toast({
        title: "Question deletion",
        description: response.data.message,
        status: response.data.status,
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });

      // Clone the current agendaPoints array after successful backend operation
      const newAgendaPoints = [...agendaPoints];

      // Remove the agenda point at the given index
      newAgendaPoints.splice(index, 1);

      // Update the state with the new agenda points array
      setAgendaPoints(newAgendaPoints);

      // Remove the item from local storage
      const storedHeadingKey = `editedAgendaPointDDQ_RFP-${selectedMinutesId}-${index}`;
      localStorage.removeItem(storedHeadingKey);

      // Update the agendaStorageKeysDDQ_RFP in local storage to reflect deletion
      const storedHeadingKeys = JSON.parse(
        localStorage.getItem("agendaStorageKeysDDQ_RFP") || "[]"
      );
      const updatedStoredKeys = storedHeadingKeys.filter(
        (key) => key !== storedHeadingKey
      );
      localStorage.setItem(
        "agendaStorageKeysDDQ_RFP",
        JSON.stringify(updatedStoredKeys)
      );

      // Optionally show a success message to the user
      console.log(
        "Agenda point deleted successfully both in frontend and backend",
        response.data
      );
    } catch (error) {
      console.error("Error deleting agenda point:", error);
      // Optionally handle errors, e.g., by showing an error message to the user
    }
  };

  const generateMinutes = async (options) => {
    const { uniqueKey, setLoading, sendRequest } = options;

    setLoading((prev) => ({ ...prev, [uniqueKey]: true }));

    toast({
      title: "Generating...",
      description:
        "Your DDQ/RFP are being Generated. File will be sent to your email. You can continue using the app.",
      status: "info",
      duration: 5000,
      isClosable: true,
      position: "bottom-right",
    });

    try {
      const response = await sendRequest();

      if (response.status === 200) {
        setSuccessfulGenerations((prev) => ({ ...prev, [uniqueKey]: true }));
        setSuccessfulGenerateAll((prev) => ({ ...prev, [uniqueKey]: true }));
        toast({
          title: "DDQ/RFP Generated Successfully",
          description:
            "File will be sent to your email or You can download it!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-right",
        });
      } else {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      handleError("Error generating DDQ/RFP", error.message);
      toast({
        title: "Generating DDQ/RFP Failed",
        description: "There was an error in Generating DDQ/RFP.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-center",
      });
    } finally {
      setLoading((prev) => ({ ...prev, [uniqueKey]: false }));
    }
  };

  const handleGenerateMinute = async (agendaIndex) => {
    if (isGenerating) {
      return; // Do not proceed if another button is being processed.
    }
    //console.log("In Generating Individual", selectedMinutesId);
    //console.log("In Generating Individual", agendaPoints);
    //console.log("In Generating Individual", agendaPoints[agendaIndex]);
    //console.log("In Generating Individual", agendaIndex);
    //setIsGenerating(true); // Set the state to indicate a button is being processed.

    // const uniqueKey = `${selectedMinutesId}-${agendaIndex}`;
    const uniqueKey = `${selectedMinutesId}-${agendaIndex}`;
    const selectedQuestionsForThisMinute = selectedDropdowns[uniqueKey] || [];
    setIsGenerating(true); // Set the state to indicate a button is being processed.

    const sendRequest = () =>
      sendAgendaPointsToBackend(
        selectedMinutesId,
        agendaPoints,
        agendaPoints[agendaIndex],
        agendaIndex,
        selectedQuestionsForThisMinute
      );

    try {
      await generateMinutes({
        uniqueKey,
        setLoading: setLoadingAgendas,
        sendRequest,
      });
    } finally {
      setIsGenerating(false); // Reset the state after completion.
    }
  };

  const handleGenerateAllClick = async () => {
    //console.log("In Generating All selectedMinutesId", selectedMinutesId);
    //console.log("In Generating All agendaPoints", agendaPoints);

    setIsGeneratingAll(true);

    const validAgendaPoints = agendaPoints.filter(
      (agenda) => agenda.question && agenda.question.trim() !== ""
    );

    const uniqueKey = selectedMinutesId;

    // Collect selected questions for all agenda points
    const allSelectedQuestions = agendaPoints.map((_, index) => {
      return selectedDropdowns[`${selectedMinutesId}-${index}`] || [];
    });
    const sendRequest = () =>
      sendAgendaPointsToBackend(
        selectedMinutesId,
        validAgendaPoints,
        null,
        null,
        allSelectedQuestions
      );

    await generateMinutes({
      uniqueKey,
      setLoading: setLoadingGenerateAll,
      sendRequest,
    });
    try {
      const minuteDetails = await fetchFileDetails(
        selectedMinutesId,
        handleError
      );
      let fetchedAgendaPoints = minuteDetails
        ? Object.values(minuteDetails.question_list)
        : [];

      setAgendaPoints(fetchedAgendaPoints);
    } catch (error) {
      handleError("Error fetching minute details", error.message);
    }

    setIsGeneratingAll(false);
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const details = await fetchFileDetails(selectedMinutesId, handleError);

        // if (details && details.minute_user_prompt) {
        if (
          details &&
          // details.system_prompt &&
          details.ddq_raw_output
        ) {
          const uniqueKey = selectedMinutesId;
          setSuccessfulGenerations((prev) => ({ ...prev, [uniqueKey]: true }));
          setSuccessfulGenerateAll((prev) => ({ ...prev, [uniqueKey]: true }));
        }
      } catch (error) {
        handleError("Error fetching DDQ/RFP details", error.message);
      }
    };

    fetchDetails();
  }, [selectedMinutesId, token]);

  const fetchPreview = async (selectedMinutesId, index, token) => {
    try {
      const response = await handlePreview(selectedMinutesId, index, token);
      setOutputText(response); // Assuming the API returns the output as plain text
      // If output comes in a different format, adjust accordingly
    } catch (error) {
      console.error("Failed to fetch the preview:", error.message);
      setOutputText(""); // Reset the output text in case of error
      toast({
        title: "Preview not available",
        description: "Sorry, we couldn't fetch the preview for you.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const generateAllButtonColorScheme =
    successfulGenerations[selectedMinutesId] ||
    successfulGenerateAll[selectedMinutesId]
      ? "green.500"
      : "blue.500"; // Replace 'yourDefaultColorScheme' with the color you use when it's not successful.

  const shouldShowGenerateMinuteButtons =
    generateAllButtonColorScheme === "green.500";

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentAgendaPoints = agendaPoints.slice(startIndex, endIndex);

  const totalPages = Math.ceil(agendaPoints.length / itemsPerPage);

  // Reset currentPage to 1 whenever selectedMinutesId changes
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedMinutesId]);

  return (
    <Box
      flex="4"
      bg={colorMode === "dark" ? "gray.700" : "white"}
      color={colorMode === "dark" ? "white" : "black"}
      display="flex"
      flexDirection="column"
      mr={2}
      mt={2}
      ml={0}
      mb={2}
      borderRadius="lg"
      p={4}
      pb={2}
      position="relative" // Added this for absolute positioning of the button
    >
      {selectedMinutesId && (
        <Button
          bgColor={generateAllButtonColorScheme}
          textColor="white"
          _hover={{}}
          onClick={handleGenerateAllClick}
          size="sm"
          position="absolute"
          top="10px"
          right="10px"
          isLoading={loadingGenerateAll[selectedMinutesId]}
          loadingText="Generating.."
          isDisabled={isGenerating || isGeneratingAll} // Disable the button based on both states
        >
          Generate All
        </Button>
      )}

      <Text
        fontSize="md"
        fontWeight="semibold"
        ml={2}
        mb="4px"
        color={colorMode === "dark" ? "white" : "#1A202C"}
      >
        Generate DDQ/RFP
      </Text>

      <Heading
        fontSize="xs"
        color={colorMode === "dark" ? "white" : "blackAlpha.700"}
        mb={0}
        pl={2}
        pb={2}
      >
        Add the Information.
      </Heading>

      <Divider mt={0} mb={0} borderColor="#E2E8F0" />

      <Box
        pl={1}
        overflowY="scroll"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {selectedMinutesId && (
          <Box mb={2} mt={2}>
            <DynamicInput title="Question" onAdd={handleAddAgendaPoint} />
          </Box>
        )}

        {/* Render only the current page's agenda points */}
        {currentAgendaPoints.map((agenda, index) => {
          const dropdownKey = `${selectedMinutesId}-${startIndex + index}`;
          return (
            <Box
              key={startIndex + index}
              mt={2}
              borderWidth="1px"
              borderRadius="md"
              p={3}
              mb={1}
            >
              <Heading as="h3" size="sm" mb={2} maxW="95%">
                {editing &&
                editing.type === "question" &&
                editing.index === startIndex + index ? (
                  <Box display="flex" alignItems="center">
                    <Input
                      size="xs"
                      value={
                        editing && editing.index === startIndex + index
                          ? agenda.question
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          startIndex + index,
                          "question",
                          e.target.value
                        )
                      }
                      onBlur={() => setEditing(null)}
                      autoFocus
                    />
                    <Button
                      size="xs"
                      ml={2}
                      onClick={() => setEditing(null)}
                      _hover={{}}
                      bgColor="blue.500"
                      textColor="white"
                    >
                      Save
                    </Button>
                  </Box>
                ) : (
                  <>
                    {agenda.question}
                    <IconButton
                      variant="ghost"
                      aria-label="Edit Heading"
                      icon={<EditIcon />}
                      colorScheme="blue"
                      boxSize="10px"
                      size="xs"
                      ml={2}
                      onClick={() =>
                        setEditing({
                          type: "question",
                          index: startIndex + index,
                        })
                      }
                    />
                    <IconButton
                      variant="ghost"
                      aria-label="Delete Question"
                      icon={<DeleteIcon />}
                      color="black"
                      boxSize="10px"
                      size="xs"
                      ml={0}
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you want to delete the question`
                          )
                        ) {
                          deleteHeading(startIndex + index);
                        }
                      }}
                    />
                  </>
                )}
              </Heading>

              {shouldShowGenerateMinuteButtons && (
                <Button
                  key={`${selectedMinutesId}-${startIndex + index}`}
                  _hover={{}}
                  bgColor="blue.500"
                  textColor="white"
                  onClick={() => handleGenerateMinute(startIndex + index)}
                  mt={2}
                  isLoading={
                    loadingAgendas[`${selectedMinutesId}-${startIndex + index}`]
                  }
                  loadingText="Generating..."
                  isDisabled={isGenerating || isGeneratingAll}
                >
                  Generate DDQ/RFP
                </Button>
              )}
              {shouldShowGenerateMinuteButtons && (
                <Button
                  bgColor="teal.500"
                  textColor="white"
                  _hover={{}}
                  onClick={() => {
                    fetchPreview(selectedMinutesId, startIndex + index, token);
                    setModalHeader(agenda.question || "Output"); // If the question is null or undefined, it will default to "Output"
                  }}
                  ml={3}
                  mt={2}
                >
                  Preview
                </Button>
              )}

              {startIndex + index > 0 && (
                <DropdownWithCheckboxes
                  questions={agendaPoints}
                  currentQuestionIndex={startIndex + index}
                  onSelect={handleDropdownSelect}
                  selectedOptions={
                    selectedDropdowns[dropdownKey] || [] // Fallback to empty array if undefined
                  }
                />
              )}
            </Box>
          );
        })}
      </Box>
        {/* Pagination Controls */}
        {selectedMinutesId && (
        <Flex justifyContent="space-between" mt={2}>
          <Button
            isDisabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => prev - 1)}
            size="xs"
            bg="black"
            color="white"
            _hover="none"
            _active="none"
          >
            Previous
          </Button>
          <Text fontSize="sm" mb={0}>
            Page {currentPage} of {totalPages}
          </Text>
          <Button
            isDisabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev) => prev + 1)}
            size="xs"
            bg="black"
            color="white"
            _hover="none"
            _active="none"
          >
            Next
          </Button>
        </Flex>
        )}
        <HStack justifyContent="space-between">
        {selectedMinutesId && successfulGenerateAll[selectedMinutesId] && (
          <FileDownload
            documentId={documentId}
            selectedMinutesId={selectedMinutesId}
          />
        )}
        {selectedMinutesId && successfulGenerateAll[selectedMinutesId] && (
          <FileSent selectedMinutesId={selectedMinutesId} />
        )}
        {selectedMinutesId && successfulGenerateAll[selectedMinutesId] && (
          <FileDownloadButton
            selectedMinutesId={selectedMinutesId}
            urlPath="/ddq-rfp/download-transcript-by-id"
            buttonText="Download Transcript"
          />
        )}
        </HStack>
    </Box>
  );
};

export default Questions;
