import React from "react";
import { saveAs } from "file-saver";
import { Button, useToast } from "@chakra-ui/react";
import { useColorMode } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";

const FileDownloadButton = ({ uploading, selectedFundsId, urlPath, buttonText }) => {
  const { colorMode } = useColorMode();
  const toast = useToast();

  const handleDownload = async () => {
    try {
      const response = await axiosInstance.post(
        `${urlPath}/${selectedFundsId}`,
        {}, // No request body needed since the ID is in the URL
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob", // Set responseType to "blob" to handle binary data
        }
      );
      // console.log("response", response.data);

      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'downloaded_file.docx'; // Default filename if not specified
      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      saveAs(response.data, filename);
    } catch (error) {
      // Handle the error response and map the backend message to the frontend
      console.log("error", error);
      if (error.response) {
        const backendMessage = error.response.data?.message || "An error occurred during download";
        toast({
          title: "Download failed",
          description: backendMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error("Download error:", error);
        toast({
          title: "Download failed",
          description: error.message || "An unknown error occurred",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Button
      bgColor={colorMode === 'light' ? 'blue.500' : 'blue.200'}
      textColor={colorMode === 'light' ? 'white' : 'black'}
      onClick={handleDownload}
      isDisabled={uploading}
      mt={2}
      p={3}
      size="sm"
      _hover="none"
    >
      {buttonText}
    </Button>
  );
};

export default FileDownloadButton;
