import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  Text,
  Input,
  Textarea,
  Select,
} from "@chakra-ui/react";

const PersonaModal = ({
  isOpen,
  onClose,
  currentPersona,
  setCurrentPersona,
  modules,
  selectedModule,
  handleModuleChange,
  handleSavePersona,
  isEditMode
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
        {isEditMode ? "Edit Persona" : "Add Persona"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        {!isEditMode && ( // Conditionally render the module dropdown
          <>
          <Heading fontSize="md" fontWeight="bold" mb={0}>
            Agent
          </Heading>
          <Text fontSize="xs" color="gray.500" mb={2}>
            Select an agent.
          </Text>
          <Select
            placeholder="Select agent"
            value={selectedModule}
            onChange={(e) => handleModuleChange(e.target.value)}
            mb={3}
          >
            {modules.map((module, index) => (
              <option key={index} value={module.module_name}>
                {module.public_module_name}
              </option>
            ))}
          </Select>
          </>
        )}
          <Heading fontSize="md" fontWeight="bold" mb={0}>
            Name
          </Heading>
          <Text fontSize="xs" color="gray.500" mb={2}>
            Give your persona a name.
          </Text>
          <Input
            placeholder="Persona Name"
            value={currentPersona.name}
            onChange={(e) =>
              setCurrentPersona({
                ...currentPersona,
                name: e.target.value,
              })
            }
            mb={3}
          />
          <Heading fontSize="md" fontWeight="bold" mb={0}>
            Prompt
          </Heading>
          <Text fontSize="xs" color="gray.500" mb={2}>
            Describe your persona.
          </Text>
          <Textarea
            placeholder="Persona Prompt"
            value={currentPersona.role_prompt}
            onChange={(e) =>
              setCurrentPersona({
                ...currentPersona,
                role_prompt: e.target.value,
              })
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button
            // color="#231F1F"
            onClick={handleSavePersona}
            bg="#231F1F"
            color="#FFFFFF"
            _hover={{ bg: "#404040" }}
            borderRadius="full"
            size="sm"
          >
            Save
          </Button>
          {/* <Button
            onClick={onClose}
            bg="#231F1F"
            color="#FFFFFF"
            _hover={{ bg: "#404040" }}
            borderRadius="full"
            size="sm"
          >
            Cancel
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PersonaModal;
