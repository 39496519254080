import React from "react";
import { Select } from "@chakra-ui/react";

const CustomSelect = ({
  options,
  value,
  onChange,
  placeholder,
  size,
  borderRadius,
  ...props
}) => {
  // console.log(`CustomSelect`,options)
  return (
    <Select
      size={size || "sm"}
      borderRadius={borderRadius || "md"}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...props}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value.module_name}>
          {option.label.public_module_name}
        </option>
      ))}
    </Select>
  );
};

export default CustomSelect;
