import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useToast } from "@chakra-ui/react";
import axiosInstance from "../../../helpers/axiosInstance";

const SaveCampaignDialog = ({
  open,
  onClose,
  paginationModel,
  filters,
  totalRows,
  unselectedRowIds,
  sortModel,
}) => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    data_name: "",
    start_page: 1,
    end_page: 1,
  });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveSubmit = async () => {
    if (!formData.data_name.trim()) {
      toast({
        title: "Validation Error",
        description: "Data Name cannot be empty.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const payload = {
      data_name: formData.data_name,
      start_page: parseInt(formData.start_page, 10),
      end_page: parseInt(formData.end_page, 10),
      page_size: paginationModel.pageSize,
      filters: Object.keys(filters).reduce((acc, key) => {
        if (filters[key]) acc[key] = filters[key];
        return acc;
      }, {}),
      excluded_lead_ids: unselectedRowIds,
      sort: sortModel,
    };
    console.log("payload",payload)
    try {
      const response = await axiosInstance.post(
        "/lead/save-as-campaign",
        payload
      );
      if (response.data.success) {
        onClose();
        toast({
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        localStorage.removeItem("unselectedRowIds");
      } else {
        console.error("Error saving campaign:", response.data.message);
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error saving campaign:", error.response.data.message);
      toast({
        title: "Error",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box>
        <DialogTitle
          fontWeight="bold"
          sx={{
            paddingBottom: "0px",
          }}
        >
          Save as Campaign Data
        </DialogTitle>
        <Typography
          variant="caption" // Use a standard text variant
          component="p" // Use a valid HTML element, e.g., "p"
          fontWeight="bold"
          color="textSecondary"
          sx={{
            marginBottom: "16px",
            marginLeft: "24px", // Align with DialogContent padding
            marginRight: "24px",
          }}
        >
          Store your filtered and selected leads into a file for efficient data
          management.
        </Typography>
      </Box>
      <DialogContent>
        {/* Data Name Field */}
        <Box mb={2}>
          <Typography
            variant="h8"
            component="h8"
            fontWeight="bold"
            gutterBottom
          >
            Enter Data Name
          </Typography>
          <TextField
            label="Data Name"
            name="data_name"
            value={formData.data_name || ""}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 3, // Customize input border radius
              },
            }}
          />
        </Box>
        <Box mb={2}>
          <Typography
            variant="h8"
            component="h8"
            fontWeight="bold"
            gutterBottom
          >
            Define Start Page
          </Typography>
          <TextField
            label="Start Page"
            name="start_page"
            value={formData.start_page || ""}
            onChange={(e) => {
              const inputValue = e.target.value;
              const numericValue =
                inputValue === "" ? "" : Math.max(1, Number(inputValue)); // Allow clearing input or ensure >= 1
              if (
                numericValue === "" ||
                numericValue <= (formData.end_page || Infinity)
              ) {
                handleFormChange({
                  target: { name: "start_page", value: numericValue },
                });
              }
            }}
            type="number"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 3, // Customize input border radius
              },
            }}
          />
        </Box>

        {/* End Page Field */}
        <Box mb={2}>
          <Typography
            variant="h8"
            component="h8"
            fontWeight="bold"
            gutterBottom
          >
            Define End Page
          </Typography>
          <TextField
            label="End Page"
            name="end_page"
            value={formData.end_page || ""}
            onChange={(e) => {
              const inputValue = e.target.value;
              const maxPage = Math.ceil(totalRows / paginationModel.pageSize); // Total rows divided by rows per page
              const numericValue =
                inputValue === "" ? "" : Math.min(maxPage, Number(inputValue)); // Allow clearing input or ensure <= maxPage
              if (
                numericValue === "" ||
                numericValue >= (formData.start_page || 1)
              ) {
                handleFormChange({
                  target: { name: "end_page", value: numericValue },
                });
              }
            }}
            type="number"
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 3, // Customize input border radius
              },
            }}
          />
          <Typography
            variant="caption" // Use a standard text variant
            component="p" // Use a valid HTML element, e.g., "p"
            fontWeight="bold"
            gutterBottom
          >
            Total Pages: {Math.ceil(totalRows / paginationModel.pageSize)}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          size="small"
          sx={{
            borderColor: "black", // Black border
            color: "black", // Black text
            borderRadius: "8px", // Rounded corners
            textTransform: "capitalize", // Optional: Normal text case
            "&:hover": {
              borderColor: "black", // Ensure border stays black on hover
            },
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={handleSaveSubmit}
          color="primary"
          variant="contained"
          size="small"
          sx={{
            borderRadius: "8px", // Rounded corners
            textTransform: "capitalize", // Optional: Normal text case
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveCampaignDialog;
