import {
  Box,
  Accordion,
  AccordionItem,
  Heading,
  AccordionButton,
  Text,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { useColorMode } from "@chakra-ui/react";
import { useUser } from "./userRole/UserContext";

const Tabs = () => {
  const { colorMode } = useColorMode();
  const location = useLocation();
  const { user, loading } = useUser();
  const role = user ? user.role : null;
  if (loading) {
    return null; // Show a loader or a placeholder here if you wish
  }

  return (
    //replace gray.700 with #444654
    <Box
      pt={6}
      p={4}
      bg={colorMode === "dark" ? "gray.700" : "white"}
      border="1px"
      borderTopRadius="lg"
      borderColor={colorMode === "dark" ? "gray.700" : "white"}
    >
      <Text
        fontSize="md"
        fontWeight="semibold"
        ml={2}
        mb="4px"
        color={colorMode === "dark" ? "gray.200" : "#1A202C"}
      >
        NexusGenie
      </Text>
      <Heading
        fontSize="xs"
        color={colorMode === "dark" ? "gray.400" : "blackAlpha.700"}
        mb={0}
        pl={2}
        pb={2}
      >
        Choose an Option.
      </Heading>
      <Accordion allowToggle>
        <AccordionItem>
          <Link to="/chat">
            <AccordionButton
              w="100%"
              color={
                location.pathname === "/chat"
                  ? "blue.500"
                  : colorMode === "dark"
                  ? "gray.200"
                  : "black"
              }
            >
              <Box flex="1" textAlign="left">
                Agents
              </Box>
            </AccordionButton>
          </Link>
        </AccordionItem>

        {["Admin", "Manager", "Employee"].includes(role) && (
          <AccordionItem>
            <Link to="/train-agent">
              <AccordionButton
                w="100%"
                color={
                  location.pathname === "/train-agent"
                    ? "blue.500"
                    : colorMode === "dark"
                    ? "gray.200"
                    : "black"
                }
              >
                <Box flex="1" textAlign="left">
                  Train Agent
                </Box>
              </AccordionButton>
            </Link>
          </AccordionItem>
        )}

        {["Admin", "Manager", "Employee"].includes(role) && (
          <AccordionItem>
            <Link to="/email">
              <AccordionButton
                w="100%"
                color={
                  location.pathname === "/email"
                    ? "blue.500"
                    : colorMode === "dark"
                    ? "gray.200"
                    : "black"
                }
              >
                <Box flex="1" textAlign="left">
                  Email
                </Box>
              </AccordionButton>
            </Link>
          </AccordionItem>
        )}

        {["Admin", "Manager", "Employee"].includes(role) && (
          <AccordionItem>
            <Link to="/persona">
              <AccordionButton
                w="100%"
                color={
                  location.pathname === "/persona"
                    ? "blue.500"
                    : colorMode === "dark"
                    ? "gray.200"
                    : "black"
                }
              >
                <Box flex="1" textAlign="left">
                  Persona
                </Box>
              </AccordionButton>
            </Link>
          </AccordionItem>
        )}

      </Accordion>
    </Box>
  );
};
export default Tabs;
