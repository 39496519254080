import React, { useState } from "react";
import { Box, Divider } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Sidebar from "../Sidebar";
import Filters from "./Filters";
import PeopleTable from "./PeopleTable";
import { Flex, Heading } from "@chakra-ui/react";

const theme = createTheme();

const People = () => {
  const [filters, setFilters] = useState({ country: "", type: "" });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value !== undefined ? value : "",
    }));
  };

  const clearFilters = () => {
    setFilters({ country: "", type: "" });
    localStorage.removeItem("unselectedRowIds");
  };

  return (
    <Flex>
      <Sidebar />
      <ThemeProvider theme={theme}>
        <Box flex="1" bg="white" width="80%" h="100vh">
          <Flex
            p={5}
            h="10.7vh"
            borderBottom="1px"
            borderColor="blackAlpha.200"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading fontSize="lg" fontWeight="semibold" mt={4} m={0}>
              People Management
            </Heading>
          </Flex>
          <Divider sx={{ mb: 2 }} />

          <Filters filters={filters} onFilterChange={handleFilterChange} onClearFilters={clearFilters} />

          <Box p={2}>
            <PeopleTable filters={filters} />
          </Box>
        </Box>
      </ThemeProvider>
    </Flex>
  );
};

export default People;
