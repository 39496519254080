import React, { useState } from "react";
import {
  Box,
  Flex,
  Spacer,
  IconButton,
  Heading,
  Button,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { ReactComponent as InsertIcon } from "../../../../assets/icons/insert_template.svg";
import EmailStatsPanel from "./EmailStatsPanel";
import EmailTemplatesDrawer from "./EmailTemplatesDrawer";
import { ViewIcon } from "@chakra-ui/icons";
import { RiInsertRowBottom } from "react-icons/ri"; // From Remix Iconsimport { HiDocumentAdd } from "react-icons/hi"; // From Heroicons
import { HiDocumentAdd } from "react-icons/hi"; // From Heroicons
import { MdPostAdd } from "react-icons/md"; 
import { FaRegEnvelope } from "react-icons/fa"; // Envelope icon
import { FaPlusCircle } from "react-icons/fa";

const TemplatePanelHeader = ({ handleInsertTemplate, formData, onOpen }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Drawer state

  const openModal = () => {
    setIsModalOpen(true);
  };
  const openDrawer = () => setIsDrawerOpen(true); // Function to open drawer
  const closeDrawer = () => setIsDrawerOpen(false); // Function to close drawer
  return (
    <Flex justifyContent="space-between" alignItems="center" mb={1}>
      <Heading fontSize="lg" fontWeight="bold">
        Template
      </Heading>
      <Spacer />
      <Tooltip
        p={1}
        label="Insert Template"
        bg="White"
        color="black"
        borderRadius="lg"
        boxShadow="lg"
      >
        <IconButton
          icon={<FaPlusCircle />}
          size="sm"
          // boxSize="8"
          // bg="none"
          // _hover="none"
          // _active="none"
          // _focus="none"
          // borderRadius="full"
          mr={2}
          onClick={openDrawer}
          aria-label="Insert Template"
        />
      </Tooltip>
      <EmailTemplatesDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        onInsertTemplate={handleInsertTemplate}
      />
      <Button mr={2} size="sm" onClick={openModal} colorScheme="blue">
        Check Template
      </Button>
      <EmailStatsPanel
        campaignId={formData.campaign_id} // Pass campaign ID
        emailSubject={formData.subject} // Pass email subject
        emailBody={formData.sequence_template}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <IconButton
        icon={<ViewIcon />}
        size="sm"
        onClick={onOpen}
        aria-label="Preview Email"
      />
    </Flex>
  );
};

export default TemplatePanelHeader;
