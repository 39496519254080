import React, { useState, useEffect } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { useToast } from "@chakra-ui/react";
import SaveCampaignDialog from "./SaveCampaignDialog"; // Import the dialog
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axiosInstance from "../../../helpers/axiosInstance";

const PeopleTable = ({ filters }) => {
  const [data, setData] = useState([]); // Store leads data
  const [loading, setLoading] = useState(false); // Loading state
  const [paginationModel, setPaginationModel] = useState({
    page: 0, // DataGrid uses 0-based indexing
    pageSize: 50, // Rows per page
  });
  const toast = useToast();
  const [totalRows, setTotalRows] = useState(0);
  const [unselectedRowIds, setUnselectedRowIds] = useState(() => {
    // Initialize unselectedRowIds from localStorage
    const savedUnselectedIds = JSON.parse(localStorage.getItem("unselectedRowIds"));
    return savedUnselectedIds || [];
  }); // Tracks unselected row IDs
  const [unmaskedData, setUnmaskedData] = useState({}); // Store unmasked email/telephone
  const [openDialog, setOpenDialog] = useState(false); // Popup state
  const [sortModel, setSortModel] = useState([]); 

  const fetchLeads = async () => {
    setLoading(true);
    try {
      const payload = {
        page: paginationModel.page + 1, // Convert 0-based index to 1-based for API
        per_page: paginationModel.pageSize,
        filters: Object.keys(filters).reduce((acc, key) => {
          if (filters[key]) acc[key] = filters[key]; // Only include active filters
          return acc;
        }, {}),
      };

      const response = await axiosInstance.post("/lead/get-leads", payload);
      if (response.data.success) {
        const leads = response.data.data.leads.map((lead) => ({
          id: lead.lead_id.trim(), // Ensure no spaces or formatting issues
          fundManager: lead.fund_manager,
          firmType: lead.firm_type,
          title: lead.title,
          firstName: lead.first_name,
          familyName: lead.family_name,
          alternativeName: lead.alternative_name,
          role: lead.role,
          jobTitle: lead.job_title,
          assetClass: lead.asset_class,
          email: lead.email,
          telephone: lead.telephone,
          city: lead.city,
          state: lead.state,
          country: lead.country,
          zipCode: lead.zip_code,
        }));

        setData(leads);
        setTotalRows(response.data.data.total_rows);
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, [filters, paginationModel]);

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const handleRowSelectionModelChange = (newSelection) => {
    const currentPageRowIds = data.map((row) => row.id);

    setUnselectedRowIds((prevUnselected) => {
      let updatedUnselected = [...prevUnselected];

      // For each row on current page
      currentPageRowIds.forEach((id) => {
        if (newSelection.includes(id)) {
          // Row is selected, remove from unselectedRowIds
          updatedUnselected = updatedUnselected.filter(
            (unselectedId) => unselectedId !== id
          );
        } else {
          // Row is unselected, add to unselectedRowIds if not already present
          if (!updatedUnselected.includes(id)) {
            updatedUnselected.push(id);
          }
        }
      });

      // Remove duplicates
      const uniqueUnselected = [...new Set(updatedUnselected)];

      // Save to localStorage
      localStorage.setItem("unselectedRowIds", JSON.stringify(uniqueUnselected));

      return uniqueUnselected;
    });
  };

  const handleSaveClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const columns = [
    // { field: "fundManager", headerName: "FUND MANAGER", width: 150 },
    // { field: "firmType", headerName: "FIRM TYPE", width: 200 },
    { field: "title", headerName: "TITLE", width: 100 },
    { field: "firstName", headerName: "FIRST NAME", width: 150 },
    { field: "familyName", headerName: "FAMILY NAME", width: 150 },
    // { field: "alternativeName", headerName: "ALTERNATIVE NAME", width: 150 },
    { field: "role", headerName: "ROLE", width: 150 },
    { field: "jobTitle", headerName: "JOB TITLE", width: 200 },
    { field: "assetClass", headerName: "ASSET CLASS", width: 150 },
    {
      field: "email",
      headerName: "EMAIL",
      width: 250,
      renderCell: (params) => {
        const id = params.row.id;
        const email = unmaskedData[id]?.email || params.value;
        return (
          <Box display="flex" alignItems="center">
            <span style={{ marginRight: "8px" }}>{email}</span>
            {email && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent row selection
                  handleUnmask(id, "email");
                }}
                disabled={!!unmaskedData[id]?.email}
              >
                <VisibilityIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            )}
          </Box>
        );
      },
    },
    {
      field: "telephone",
      headerName: "TELEPHONE",
      width: 200,
      renderCell: (params) => {
        const id = params.row.id;
        const telephone = unmaskedData[id]?.telephone || params.value;
        return (
          <Box display="flex" alignItems="center">
            <span style={{ marginRight: "8px" }}>{telephone}</span>
            {telephone && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent row selection
                  handleUnmask(id, "telephone");
                }}
                disabled={!!unmaskedData[id]?.telephone}
              >
                <VisibilityIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            )}
          </Box>
        );
      },
    },
    { field: "city", headerName: "CITY", width: 150 },
    { field: "state", headerName: "STATE", width: 150 },
    { field: "country", headerName: "COUNTRY", width: 150 },
    { field: "zipCode", headerName: "ZIP CODE", width: 100 },
  ];

  const handleUnmask = async (id, field) => {
    try {
      const payload = { field };
      const response = await axiosInstance.post(
        `/lead/unmask-field/${id}`,
        payload
      );
      if (response.data.success) {
        const unmaskedValue = response.data.data.value; // Extract unmasked value
        setUnmaskedData({ [id]: { [field]: unmaskedValue } }); // Reset and store only the new unmasked value
      } else {
        console.error("Failed to unmask:", response.data.message);
      }
    } catch (error) {
      console.error("Error unmasking data:", error);
    }
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel); // Update sort model state
    // console.log("Current Sort Model:", newSortModel); // Log the current sort model
  };

  return (
    <>
      {/* Save and Select All Buttons aligned to the right */}
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        mb={2}
        gap={2}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </Box>

      {/* DataGrid */}
      <DataGrid
        rows={data}
        columns={columns}
        pagination
        paginationModel={paginationModel}
        rowCount={totalRows}
        paginationMode="server"
        onPaginationModelChange={handlePaginationChange}
        sortModel={sortModel} // Pass sortModel to DataGrid
        onSortModelChange={handleSortModelChange} // Handle sort changes
        checkboxSelection
        rowSelectionModel={data
          .map((row) => row.id)
          .filter((id) => !unselectedRowIds.includes(id))}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        pageSizeOptions={[10, 20, 50]}
        loading={loading}
        getRowId={(row) => row.id}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            fontWeight: "bold",
          },
          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: "#f9f9f9",
          },
          "& .MuiTablePagination-toolbar": {
            padding: 0,
          },
          "& .MuiTablePagination-selectLabel": {
            margin: 0,
            padding: 0,
            fontSize: "14px",
          },
          "& .MuiTablePagination-displayedRows": {
            margin: 0,
            fontSize: "14px",
          },
          height: "47vh",
        }}
      />
      <SaveCampaignDialog
        open={openDialog}
        onClose={handleDialogClose}
        paginationModel={paginationModel}
        filters={filters}
        totalRows={totalRows}
        unselectedRowIds={unselectedRowIds}
        sortModel={sortModel}
      />
    </>
  );
};

export default PeopleTable;
