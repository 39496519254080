import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Heading,
  useToast,
  useColorMode,
  List,
  ListItem,
  IconButton,
  Flex,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import Tabs from "../Tabs";
import {
  fetchOrganizations,
  fetchModules,
  fetchPersonas,
  createPersona,
  updatePersona,
  deletePersona,
} from "../../api/persona/api";
import PersonaModal from "./PersonaModal";

const Persona = () => {
  const { colorMode } = useColorMode();
  const [personas, setPersonas] = useState([]);
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");
  const [organization, setOrganization] = useState("");
  const [currentPersona, setCurrentPersona] = useState({
    name: "",
    role_prompt: "",
    module_name: "",
    organization: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditMode, setIsEditMode] = useState(false);
  const toast = useToast();

  useEffect(() => {
    loadOrganization();
  }, []);

  const loadOrganization = async () => {
    const data = await fetchOrganizations(toast);
    setOrganization(data.data.organization);
  };

  useEffect(() => {
    loadModules();
  }, []);

  const loadModules = async () => {
    const data = await fetchModules(toast);
    setModules(Array.isArray(data.data) ? data.data : []);
  };

  const handleModuleChange = (module) => {
    setSelectedModule(module);
    setCurrentPersona((prevPersona) => ({
      ...prevPersona,
      module_name: module,
    }));
  };

  useEffect(() => {
    loadPersonas();
  }, []);

  const loadPersonas = async () => {
    const data = await fetchPersonas(toast);
    setPersonas(Array.isArray(data.data) ? data.data : []);
  };

  const handleAddPersona = () => {
    setCurrentPersona({
      name: "",
      role_prompt: "",
      module_name: selectedModule,
      organization,
    });
    onOpen();
  };

  const handleEditPersona = (persona) => {
    setCurrentPersona(persona);
    setIsEditMode(true);
    onOpen();
  };

  const handleDeletePersona = async (id) => {
    const success = await deletePersona(id, toast);
    if (success !== null) {
      loadPersonas();
    }
  };

  const handleSavePersona = async () => {
    let success;

    if (isEditMode) {
      // Update payload without module_name for updating a persona
      const updatePayload = {
        name: currentPersona.name,
        role_prompt: currentPersona.role_prompt,
        organization: organization || currentPersona.organization,
      };
      success = await updatePersona(currentPersona._id, updatePayload, toast);
    } else {
      // Create payload with module_name for creating a new persona
      const createPayload = {
        name: currentPersona.name,
        role_prompt: currentPersona.role_prompt,
        module_name: selectedModule,
        organization: organization,
      };
      success = await createPersona(createPayload, toast);
    }

    if (success) {
      loadPersonas(); // Refresh personas after create or update
      onClose();
      setIsEditMode(false); // Reset edit mode
    }
  };

  // Group personas by module name
  const groupedPersonas = personas.reduce((acc, persona) => {
    const moduleName = persona.public_module_name || "Uncategorized";
    if (!acc[moduleName]) acc[moduleName] = [];
    acc[moduleName].push(persona);
    return acc;
  }, {});

  return (
    <Box
      display={{ base: "block", md: "flex" }}
      width="100%"
      height="calc(100vh - 52px)"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
    >
      <Box
        w="20%"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        mr={0}
        mt={2}
        ml={2}
        mb={2}
        border="1px"
        borderTopRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
      >
        <Tabs />
      </Box>
      <Box
        width={{ base: "100%", md: "80%" }}
        bg={colorMode === "dark" ? "gray.700" : "white"}
        p={4}
        mr={0}
        m={2}
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
      >
        <Box bg="none">
          <HStack justifyContent="space-between" mb={4}>
            <VStack align="left" spacing={0}>
              <Heading size="md" fontWeight="bold" mb={0}>
                Persona
              </Heading>
              <Text fontSize="xs" color="gray.500" mb={2}>
                Create a distinct personality for your agent that comes through
                clearly when asked questions.
              </Text>
            </VStack>
            <Button
              color="white"
              size="sm"
              borderRadius="xl"
              bg="black"
              _hover="black"
              onClick={handleAddPersona}
            >
              Add Persona
            </Button>
          </HStack>
          <Box
            width="100%"
            height={{ base: "82vh", md: "74vh" }}
            mt={5}
            overflowY="auto"
            p={5}
            border="1px solid"
            borderColor="gray.100"
            borderRadius="xl"
            bg="white"
          >
            {Object.entries(groupedPersonas).map(([moduleName, personas]) => (
              <Box key={moduleName} mb={5}>
                <Heading size="md" fontWeight="bold" color="gray.600" mb={2}>
                  {moduleName}
                </Heading>
                <List spacing={3} pl={0}>
                  {personas.map((persona) => (
                    <ListItem
                      key={persona._id}
                      p={3}
                      bg="white"
                      borderWidth={1}
                      borderRadius="xl"
                      shadow="sm"
                    >
                      <Flex justify="space-between" align="center">
                        <Box>
                          <Text fontWeight="bold" mb={0}>
                            {persona.name}
                          </Text>
                          <Text fontSize="sm" color="gray.500">
                            {persona.role_prompt}
                          </Text>
                        </Box>
                        <HStack>
                          <IconButton
                            icon={<EditIcon />}
                            size="xs"
                            variant="unstyled"
                            onClick={() => handleEditPersona(persona)}
                          />
                          <IconButton
                            icon={<DeleteIcon />}
                            size="xs"
                            variant="unstyled"
                            color="red"
                            onClick={() => handleDeletePersona(persona._id)}
                          />
                        </HStack>
                      </Flex>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          <PersonaModal
            isOpen={isOpen}
            onClose={onClose}
            currentPersona={currentPersona}
            setCurrentPersona={setCurrentPersona}
            modules={modules}
            selectedModule={selectedModule}
            handleModuleChange={handleModuleChange}
            handleSavePersona={handleSavePersona}
            isEditMode={isEditMode}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Persona;
