import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Flex,
  Box,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axiosInstance from "../../../helpers/axiosInstance";
import { useParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import EditSequenceFormBox from "./EditSequenceFormBox/EditSequenceFormBox";
import SequenceHeader from "./SequenceHeader";
import SequenceItem from "./SequenceItem";
import {
  convertHtmlToPlainText,
  decodeCampaignData,
  modifySequenceTemplate,
} from "../../../utils/helpers";
import useCampaignData from "../../../hooks/Campaign/useCampaignData"; // Import your hook

function Sequences() {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const history = useHistory();
  const { campaignData } = useParams();
  const { campaignId, isFromBackend } = decodeCampaignData(campaignData);
  const [delayNumber, setDelayNumber] = useState("");
  const [delayUnit, setDelayUnit] = useState("m");
  const [sequenceCount, setSequenceCount] = useState(0);
  const [currentCampaignId, setCurrentCampaignId] = useState(null);
  const [currentSequenceId, setCurrentSequenceId] = useState("");
  const [currentSequenceIndex, setCurrentSequenceIndex] = useState(null);
  const [isEditView, setIsEditView] = useState(false); // Add this state

  // Use the custom hook for campaign data
  const {
    campaigns,
    setCampaigns,
    campaignInsights,
    sequencesInsights,
    loading,
    error,
  } = useCampaignData(campaignId, isFromBackend, toast);

  const [formData, setFormData] = useState({
    sequence_name: "",
    delay_time: "",
    subject: "",
    sequence_template: "",
    // is_active: false,
    signature: false,
    meeting_invite: false,
    meeting_note: "",
    meeting_name: "",
    meeting_start_date: "",
    meeting_end_date: "",
  });

  const currentCampaign = campaigns.find(
    (campaign) => campaign.campaign_id === campaignId
  );

  const initialFormData = {
    sequence_name: "",
    delay_time: "",
    subject: "",
    sequence_template: "",
    // is_active: false,
    signature: false,
    meeting_invite: false,
    meeting_note: "",
    meeting_name: "",
    meeting_start_date: "",
    meeting_end_date: "",
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  useEffect(() => {
    const fetchSignature = async () => {
      try {
        const response = await axiosInstance.get("/signature/fetch-signature");
        if (response.data.success) {
          const fetchedSignature = response.data.data[0].signature_text; // Assuming you want the first signature
        } else {
          console.error("Error fetching signature:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching signature:", error);
      }
    };

    fetchSignature();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleMeetingCheckboxChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  // Function to handle adding a new sequence to the specific campaign

  const handleSaveCampaign = async (campaignId) => {
    // Retrieve all campaigns from local storage
    const storedCampaigns = localStorage.getItem("campaigns");
    let campaigns = storedCampaigns ? JSON.parse(storedCampaigns) : [];

    // Find the specific campaign by ID
    const currentCampaign = campaigns.find(
      (campaign) => campaign.campaign_id === campaignId
    );

    if (!currentCampaign) {
      console.error("Campaign not found");
      return; // Exit the function if no campaign is found
    }

    // Construct the payload with the current campaign data
    const campaignData = {
      campaign_name: currentCampaign.campaign_name,
      module_name: currentCampaign.module_name,
      persona: currentCampaign.persona,
      connected_email: currentCampaign.connected_email,
      mission_statement: currentCampaign.mission_statement,
      language: "Auto",
      data_id: currentCampaign.data_id,
      allowed_days: currentCampaign.allowed_days,
      start_time: currentCampaign.start_time,
      end_time: currentCampaign.end_time,
      time_zone: currentCampaign.time_zone,
      emails_per_day: currentCampaign.emails_per_day,
      ai_sent_allowed: currentCampaign.ai_sent_allowed,
      ai_scheduling: currentCampaign.ai_scheduling,
      sequence_list: currentCampaign.sequences,
    };

    console.log("Campaign payload", campaignData);

    try {
      const response = await axiosInstance.post(
        "/campaign/create-campaign",
        campaignData
      );

      if (response.status === 201) {
        toast({
          title: "Success",
          description: "Campaign saved successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Remove the saved campaign from local storage
        campaigns = campaigns.filter(
          (campaign) => campaign.campaign_id !== campaignId
        );
        localStorage.setItem("campaigns", JSON.stringify(campaigns));
        history.push(`/campaign`);
      } else {
        throw new Error(response.message || "Failed to save campaign");
      }
    } catch (error) {
      console.error("Error saving campaign:", error);
    }
  };

  const handleSequenceClick = (sequence, index, sequenceId) => {
    // Parse delay_time to extract number and unit
    const matches = sequence.delay_time.match(/^(\d+)([mhd])$/);
    let number = "";
    let unit = "m"; // default to minutes if no match is found
    if (matches) {
      number = matches[1];
      unit = matches[2];
    }

    // Track the current campaign ID and sequence index
    setCurrentCampaignId(currentCampaign.campaign_id);
    setCurrentSequenceId(sequenceId);
    setCurrentSequenceIndex(index);

    // Update state for the form and individual components
    setFormData({
      sequence_name: sequence.sequence_name,
      delay_time: sequence.delay_time,
      subject: sequence.subject,
      sequence_template: sequence.sequence_template,
      signature: sequence.signature,
      meeting_invite: sequence.meeting_invite,
      meeting_note: sequence.meeting_note,
      meeting_name: sequence.meeting_name,
      meeting_start_date: sequence.meeting_start_date,
      meeting_end_date: sequence.meeting_end_date,
    });
    setDelayNumber(number);
    setDelayUnit(unit);

    setSequenceCount(index);

    // Set the edit view to true to display the edit form
    setIsEditView(true); // This will toggle the view to the sequence editor
  };

  const handleSaveSequence = () => {
    if (
      !formData.sequence_name ||
      !formData.delay_time ||
      !formData.sequence_template ||
      (sequenceCount < 1 && !formData.subject)
    ) {
      toast({
        title: "Error",
        description: "All fields are required.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const updatedCampaigns = campaigns.map((campaign) => {
      if (campaign.campaign_id === currentCampaignId) {
        const updatedSequences = campaign.sequences.map((seq, index) => {
          if (index === currentSequenceIndex) {
            return { ...seq, ...formData }; // Update the matching sequence
          }
          return seq; // Return other sequences unchanged
        });

        return {
          ...campaign,
          sequences: updatedSequences, // Use updated sequences
        };
      }
      return campaign; // Return other campaigns unchanged
    });

    setCampaigns(updatedCampaigns);

    toast({
      title: "Sequence Saved",
      description: "Your sequence has been successfully saved.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Flex>
      <Sidebar />
      <Box flex="1" bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}>
        {/* Conditionally render either the Sequence list view or the Edit form view */}
        {!isEditView ? (
          <>
            <SequenceHeader
              handleSaveCampaign={handleSaveCampaign}
              campaignId={campaignId}
              handleChange={handleChange}
              handleMeetingCheckboxChange={handleMeetingCheckboxChange}
              formData={formData}
              setFormData={setFormData}
              dataId={currentCampaign?.data_id} // Add null check for currentCampaign.data_id
              isFromBackend={isFromBackend}
              delayNumber={delayNumber}
              setDelayNumber={setDelayNumber}
              delayUnit={delayUnit}
              setDelayUnit={setDelayUnit}
              sequenceCount={sequenceCount} // Pass the sequence count
              setSequenceCount={setSequenceCount}
              // resetFormData={resetFormData}
              currentCampaign={currentCampaign}
            />
            {currentCampaign && ( // Add check here to ensure currentCampaign exists
              <SequenceItem
                handleSequenceClick={handleSequenceClick}
                currentCampaign={currentCampaign}
                isFromBackend={isFromBackend}
                campaignInsights={campaignInsights}
                sequencesInsights={sequencesInsights}
              />
            )}
          </>
        ) : (
          <EditSequenceFormBox
            handleChange={handleChange}
            handleMeetingCheckboxChange={handleMeetingCheckboxChange}
            handleSaveSequence={handleSaveSequence}
            formData={formData}
            setFormData={setFormData}
            dataId={currentCampaign?.data_id} // Add null check for currentCampaign.data_id
            isFromBackend={isFromBackend}
            delayNumber={delayNumber}
            setDelayNumber={setDelayNumber}
            delayUnit={delayUnit}
            setDelayUnit={setDelayUnit}
            sequenceCount={sequenceCount}
            setIsEditView={setIsEditView}
            campaignId={campaignId}
            currentSequenceId={currentSequenceId}
            // fetchCampaignDetails={fetchCampaignDetails}
            setCampaigns={setCampaigns}
            currentCampaign={currentCampaign}
          />
        )}
      </Box>
    </Flex>
  );
}
export default Sequences;
