import React, { useEffect, useState } from "react";
import {
  VStack,
  useToast,
  Button,
  Flex,
  Text,
  Divider,
  Box,
  useColorMode,
  Spinner,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";
import ReportDisplay from "./ReportDisplay";
import FileDownload from "./FileDownload";
import SummaryDisplay from "./SummaryDisplay";

const Output = ({
  selectedFundsId,
  selectedFund,
  report,
  setReport,
  esmaReport,
  setEsmaReport,
  summary,
  setSummary,
  lastStep,
  esmaLastStep,
  isLoading,
  setIsLoading,
  taskId,
  setTaskId,
  fetchReportData,
}) => {
  const toast = useToast();
  const documentId = 1;
  const { colorMode } = useColorMode();
  const [status, setStatus] = useState(null); // Store the current task status

  // Helper function to check if the report is finalized
  const isFinalized = () =>
    lastStep === "finalize" || esmaLastStep === "finalize";

  // Function to fetch the task status using task ID
  const fetchFundDetails = async (taskId) => {
    try {
      const response = await axiosInstance.get(
        `fund_docs/task-status/${taskId}`
      );
      console.log("status", response.data);
      if (response.status === 200) {
        const { state, progress, result } = response.data;
        setStatus(state);

        if (state === "PENDING" && !isFinalized()) {
          toast({
            title: "Report is still being generated",
            description: `Progress: ${progress}%`,
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        } else if (state === "FAILURE" && !isFinalized()) {
          toast({
            title: "Report generation failed",
            description: result || "There was an issue generating the report.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Error fetching task status",
          description: `Unexpected status: ${response.status}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error caught in fetchFundDetails:", error);
      toast({
        title: "Error fetching report status",
        description: error.response?.data.message || "Failed to fetch status",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Function to fetch the task ID if needed
  const fetchTaskId = async () => {
    try {
      const response = await axiosInstance.get(
        `fund_docs/get-task-id/${selectedFundsId}`
      );
      if (response.status === 200) {
        const fetchedTaskId = response.data.data.task_id;
        setTaskId(fetchedTaskId);
      }
    } catch (error) {
      console.error("Error caught in fetchTaskId:", error);
    }
  };

  useEffect(() => {
    if (selectedFundsId) {
      setTaskId(null); // Clear the task ID when switching funds
      setStatus(null);
      setReport([]); // Clear the report data for the new fund
      setEsmaReport([]); // Clear the esma report
      setSummary(null); // Clear the summary for the new fund

      fetchTaskId(); // Fetch a new task ID if necessary
    }
  }, [selectedFundsId]);

  // Fetch task status whenever taskId changes
  useEffect(() => {
    if (taskId) {
      fetchFundDetails(taskId);
    }
  }, [taskId]);

  // Reusable component to render the Generate or Re-generate button
  const GenerateButton = ({ label }) => (
    <VStack
      spacing={4}
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <Button
        size="md"
        colorScheme="blue"
        onClick={() => fetchReportData(selectedFundsId)}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {isLoading ? <Spinner size="sm" /> : label}
      </Button>
      <Text fontSize="lg" color="blackAlpha.700" fontWeight="medium">
        {label === "Generate"
          ? "The report is not available. Please generate it."
          : "The report needs to be finalized. Please re-generate."}
      </Text>
    </VStack>
  );

  // Reusable component to render report tabs
  const ReportTabs = () => (
    <Tabs width="full">
      <TabList
        width="full"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" gap={4}>
          {selectedFundsId &&
            Array.isArray(report) &&
            report?.length > 0 &&
            lastStep === "finalize" && <Tab>Report</Tab>}
          {selectedFundsId &&
            Array.isArray(esmaReport) &&
            esmaReport?.length > 0 &&
            esmaLastStep === "finalize" && (
              <Tab>Gap Analysis: ESMA Guidelines Report</Tab>
            )}
        </Box>
        {selectedFundsId && Array.isArray(report) && report?.length > 0 && (
          <Box ml="auto">
            <FileDownload
              documentId={documentId}
              selectedFundsId={selectedFundsId}
            />
          </Box>
        )}
      </TabList>

      <TabPanels>
        {/* Report Tab */}
        {lastStep === "finalize" && (
          <TabPanel>
            <Box
              height="75vh"
              overflowY="scroll"
              scrollBehavior="hidden"
              sx={{
                "&::-webkit-scrollbar": { display: "none" },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              {summary && Object.keys(summary)?.length > 0 && (
                <SummaryDisplay summary={summary} />
              )}
              {report?.length > 0 && <ReportDisplay report={report} />}
            </Box>
          </TabPanel>
        )}
        {/* ESMA Report Tab */}
        {esmaLastStep === "finalize" && (
          <TabPanel>
            <Box
              height="75vh"
              overflowY="scroll"
              scrollBehavior="hidden"
              sx={{
                "&::-webkit-scrollbar": { display: "none" },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              {esmaReport?.length > 0 && <ReportDisplay report={esmaReport} />}
            </Box>
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );

  return (
    <VStack spacing={2} width="full">
      {!selectedFundsId ? (
        <VStack spacing={4} justifyContent="center" alignItems="center" height="80vh">
          <Text fontSize="lg" color="blackAlpha.700" fontWeight="medium">
            No fund selected. Please select a fund or create fund to see reports.
          </Text>
        </VStack>
      ) : status ? (
        status === "SUCCESS" ? (
          isFinalized() ? (
            <ReportTabs />
          ) : (
            <GenerateButton label="Re-Generate" />
          )
        ) : status === "FAILURE" ? (
          isFinalized() ? (
            <ReportTabs />
          ) : (
            <GenerateButton label="Re-Generate" />
          )
        ) : status === "PENDING" ? (
          isFinalized() ? (
            <>
              <ReportTabs />
            </>
          ) : (
            <VStack
              spacing={4}
              justifyContent="center"
              alignItems="center"
              height="80vh"
            >
              <Text fontSize="lg" color="blackAlpha.700" fontWeight="medium">
                PitchComply report is still being generated. Please check later...
              </Text>
            </VStack>
          )
        ) : isFinalized() ? (
          <ReportTabs />
        ) : (
          <GenerateButton label="Generate" />
        )
      ) : isFinalized() ? (
        <ReportTabs />
      ) : (
        <GenerateButton label="Generate" />
      )}
    </VStack>
  );
  
};

export default Output;
