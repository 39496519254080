import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { Button, useToast } from "@chakra-ui/react";
import { logout } from "../../auth";
import { useColorMode } from "@chakra-ui/react";
// import '../styles/fileHandlingPage.css';
import axiosInstance from "../../helpers/axiosInstance";

const FileSent = ({ uploading, selectedMinutesId }) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const handleFileSent = async () => {
    // console.log("Downloading function", selectedMinutesId);
    try {
      const response = await axiosInstance.post(
        "/ddq-rfp/send-file-via-email",
        { str_id: selectedMinutesId }
      );

      toast({
        title: "Message",
        description: response.data.message,
        status: response.data.status,
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while sending the email",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Button
      _hover={{}}
      bgColor="green.500"
      textColor="white"
      w="33%"
      size="sm"
      onClick={handleFileSent}
      isDisabled={uploading}
      ml={2}
      mt={2}
      p={3}
    >
      Email it!
    </Button>
  );
};

export default FileSent;
