import React, { useState, useRef } from "react";
import {
  Box,
  Input,
  Text,
  Link,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";

const FileInputArea = ({ onFilesSelected }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFilesChange = (files) => {
    const newFiles = Array.from(files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    onFilesSelected([...selectedFiles, ...newFiles]);
  };

  const handleFileRemove = (index, e) => {
    e.stopPropagation();
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    onFilesSelected(updatedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleFilesChange(files);
  };

  return (
    <Box textAlign="center">
      <Box
        border="1px dashed"
        borderColor="gray.300"
        borderRadius="md"
        p={8}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        cursor="pointer"
        onClick={() => fileInputRef.current.click()}
      >
        <FiUpload size={40} style={{ margin: "0 auto" }} />
        <Text fontSize="md" fontWeight="bold" mb={2}>
          Drop the files here or <Link color="#3C9AFF">choose a file</Link>
        </Text>
        <Text fontSize="sm" color="gray.500" mb={1}>
          Allowed types: xlsx, csv, txt, docx, pdf
        </Text>
        <Input
          type="file"
          ref={fileInputRef}
          display="none"
          multiple
          onChange={(e) => handleFilesChange(e.target.files)}
        />
        {selectedFiles.length > 0 && (
          <Wrap mt={4} justify="center">
            {selectedFiles.map((file, index) => (
              <WrapItem key={index}>
                <Tag
                  size="md"
                  borderRadius="full"
                  variant="solid"
                  colorScheme="blue"
                  maxW="300px" // Set max width for the Tag
                  overflow="hidden" // Hide overflow text
                  textOverflow="ellipsis" // Add ellipsis for overflow text
                >
                  <TagLabel isTruncated>{file.name}</TagLabel>
                  <TagCloseButton onClick={(e) => handleFileRemove(index, e)} />
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        )}
      </Box>
    </Box>
  );
};

export default FileInputArea;
