import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  IconButton,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

const EditModuleModal = ({ moduleName, onEdit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newModuleName, setNewModuleName] = useState("");
  const toast = useToast();

  const handleSave = () => {
    if (!newModuleName.trim()) {
      toast({
        title: "Error",
        description: "New module name cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    onEdit(moduleName, newModuleName);
    onClose();
  };

  const handleOpen = () => {
    setNewModuleName(""); // Clear the input field when modal opens
    onOpen();
  };

  return (
    <>
      <Tooltip label="Edit" fontSize="sm">
        <IconButton
          variant="ghost"
          aria-label={`Edit ${moduleName}`}
          icon={<EditIcon />}
          onClick={handleOpen} // Use handleOpen instead of onOpen
          size="xs"
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Agent Name</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>New Agent Name</FormLabel>
              <Input
                placeholder="Enter new agent name"
                value={newModuleName}
                onChange={(e) => setNewModuleName(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="black"
              color="white"
              _hover="none"
              mr={3}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditModuleModal;
