import React from 'react';
import { Box, VStack, Text, HStack, Circle, Heading, Flex, Tooltip } from '@chakra-ui/react';

const ReportDisplay = ({ report }) => {
  // console.log("report",report);
  // Function to determine the highest priority color
  const getPriorityColor = (prompts) => {
    let priority = 3; // Start with the lowest priority (green)
    const colorPriority = { 'red': 1, 'yellow': 2, 'green': 3 };

    prompts.forEach(prompt => {
      const complianceLevel = prompt.response?.compliance_level || 'gray';
      if (colorPriority[complianceLevel] < priority) {
        priority = colorPriority[complianceLevel];
      }
    });

    const priorityColor = Object.keys(colorPriority).find(key => colorPriority[key] === priority);
    return priorityColor || 'gray'; // Return the color or gray if none found
  };

  // Function to get hover text based on color
  const getHoverText = (color) => {
    const hoverTexts = {
      'yellow': 'Mild changes required',
      'green': 'Correct',
      'red': 'Major changes required'
    };
    return hoverTexts[color] || 'No data'; // Default text if color is not found
  };

  return (
    <VStack spacing={4} align="stretch">
      {report.map((section, index) => (
        section.response_exist === true && (
          <Box key={index} p={5} shadow="md" borderWidth="1px">
            <Flex justifyContent="space-between" alignItems="center">
              <Heading fontSize="xl">
                {section.category}
              </Heading>
              <Tooltip label={getHoverText(getPriorityColor(section.prompts))} hasArrow>
                <Circle 
                  size="25px" 
                  bg={getPriorityColor(section.prompts)} 
                  m={2}
                />
              </Tooltip>
            </Flex>
            <Heading fontSize="md" mb={4}>
              {section.type}
            </Heading>
            {section.prompts.map((prompt, idx) => (
              <HStack key={idx} justifyContent="space-between">
                <Text>{prompt.response.question_response}</Text>
              </HStack>
            ))}
          </Box>
        )
      ))}
    </VStack>
  );
};

export default ReportDisplay;




// import React from 'react';
// import { Box, VStack, Text, HStack, Circle, Heading, Flex } from '@chakra-ui/react';

// const ReportDisplay = ({ report }) => {
//   // Function to determine the highest priority color
//   const getPriorityColor = (prompts) => {
//     let priority = 3; // Start with the lowest priority (green)
//     const colorPriority = { 'red': 1, 'yellow': 2, 'green': 3 };

//     prompts.forEach(prompt => {
//       const complianceLevel = prompt.response?.compliance_level || 'gray';
//       if (colorPriority[complianceLevel] < priority) {
//         priority = colorPriority[complianceLevel];
//       }
//     });

//     const priorityColor = Object.keys(colorPriority).find(key => colorPriority[key] === priority);
//     return priorityColor || 'gray'; // Return the color or gray if none found
//   };

//   return (
//     <VStack spacing={4} align="stretch">
//       {report.map((section, index) => (
//         section.response_exist === true && (
//           <Box key={index} p={5} shadow="md" borderWidth="1px">
//             <Flex justifyContent="space-between" alignItems="center">
//               <Heading fontSize="xl">
//                 {section.category}
//               </Heading>
//               <Circle 
//                 size="25px" 
//                 bg={getPriorityColor(section.prompts)} 
//                 m={2}
//                 // mb={4}
//               />
//             </Flex>
//             <Heading fontSize="md" mb={4}>
//               {section.type}
//             </Heading>
//             {section.prompts.map((prompt, idx) => (
//               <HStack key={idx} justifyContent="space-between">
//                 <Text>{prompt.response.question_response}</Text>
//               </HStack>
//             ))}
//           </Box>
//         )
//       ))}
//     </VStack>
//   );
// };

// export default ReportDisplay;
