import React, { useRef, useState, useEffect, useCallback } from "react";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Box,
  useDisclosure,
  Flex,
  useColorMode,
  Heading,
  IconButton,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useLocation, useHistory } from "react-router-dom";
import axiosInstance from "../../../../helpers/axiosInstance";
import SequenceDetailsPanel from "./SequenceDetailsPanel";
import {
  convertHtmlToPlainText,
  modifySequenceTemplate,
} from "../../../../utils/helpers";
import useCampaignData from "../../../../hooks/Campaign/useCampaignData"; // Import your hook
import Sidebar from "../../Sidebar";
import TemplatePanel from "./TemplatePanel";

const SequenceFormBox = () => {
  const templateEditorRef = useRef(null); // Ref to access TemplatePanel's methods // Reference to access TemplatePanel functions
  const toast = useToast();
  const { colorMode } = useColorMode();
  const location = useLocation();
  const history = useHistory();
  const [delayNumber, setDelayNumber] = useState("");
  const [delayUnit, setDelayUnit] = useState("m");
  const [formData, setFormData] = useState({
    sequence_name: "",
    delay_time: "",
    subject: "",
    sequence_template: "",
    // is_active: false,
    signature: false,
    meeting_invite: false,
    meeting_note: "",
    meeting_name: "",
    meeting_start_date: "",
    meeting_end_date:"",
    // time_zone,
  });
  // Destructure the state passed from the previous page
  const { dataId, isFromBackend, sequenceCount, campaignId } = location.state;

  const {
    campaigns,
    setCampaigns,
    campaignInsights,
    sequencesInsights,
    loading,
    error,
  } = useCampaignData(campaignId, isFromBackend, toast);

  const [dynamicVariables, setDynamicVariables] = useState([]);
  const [signature, setSignature] = useState("");

  const handleBackClick = () => {
    history.goBack(); // Go back to the previous page
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleMeetingCheckboxChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  // Function to handle adding a new sequence to the specific campaign
  const handleAddSequence = (formData) => {
    // console.log("In Form Data at submission:", formData);

    // Check if any of the required fields are empty or not provided
    if (
      !formData.sequence_name ||
      !formData.delay_time ||
      !formData.sequence_template ||
      (sequenceCount < 1 && !formData.subject)
    ) {
      // Handle the error: Alert, throw an error, or another form of notification
      toast({
        title: "Fields",
        description: "All fields are required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Exit the function if there is an error
    }

    // const modifiedSequenceTemplate = modifySequenceTemplate(
    //   formData.sequence_template
    // );

    const newSequence = {
      ...formData,
      sequence_template: formData.sequence_template, // Convert to plain text
      subject: formData.subject, // Convert to plain text
    };

    // Update the specific campaign with new sequence
    const updatedCampaigns = campaigns.map((campaign) => {
      if (campaign.campaign_id === campaignId) {
        return { ...campaign, sequences: [...campaign.sequences, newSequence] };
      }
      return campaign;
    });

    setCampaigns(updatedCampaigns);

    // Reset form data to initial state
    setFormData({
      sequence_name: "",
      delay_time: "",
      subject: "",
      sequence_template: "",
      // is_active: false,
      signature: false,
      meeting_invite: false,
      meeting_note: "",
      meeting_name: "",
      meeting_start_date:"",
      meeting_end_date:"",
      // time_zone,
    });
    history.goBack();
  };

  // Fetch dynamic variables
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/template/${dataId}`);
        const data = response.data.data;
        const mappedData = Object.entries(data).map(([key, value]) => ({
          key: `{{${key}}}`,
          value: value.toString(),
        }));
        setDynamicVariables(mappedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Fetch signature
  useEffect(() => {
    const fetchSignature = async () => {
      try {
        const response = await axiosInstance.get("/signature/fetch-signature");
        if (response.data.success) {
          const fetchedSignature = response.data.data[0].signature_text; // Assuming you want the first signature
          setSignature(fetchedSignature);
        } else {
          console.error("Error fetching signature:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching signature:", error);
      }
    };

    fetchSignature();
  }, []);

  // SequenceFormBox.js

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFormData((prevFormData) => {
      let updatedTemplate = prevFormData.sequence_template;

      // Append or remove the signature based on the checkbox state, without adding extra line
      if (name === "signature" && checked) {
        if (!updatedTemplate.includes(signature)) {
          updatedTemplate += signature; // Append signature without newline
        }
        if (templateEditorRef.current) {
          templateEditorRef.current.insertSignature(signature);
        }
      } else if (name === "signature" && !checked) {
        updatedTemplate = updatedTemplate.replace(signature, ""); // Remove signature
      }

      return {
        ...prevFormData,
        [name]: checked,
        sequence_template: updatedTemplate, // Update template in formData
      };
    });
  };

  const handleVariableClick = (variable, editorType) => {
    if (templateEditorRef.current) {
      templateEditorRef.current.insertVariable(variable.key, editorType);
    }
  };

  const processTemplate = (template = "") => {
    let processedTemplate = template;
    dynamicVariables.forEach((variable) => {
      const regex = new RegExp(variable.key, "g");
      processedTemplate = processedTemplate.replace(regex, variable.value);
    });
    return processedTemplate;
  };

  return (
    <Flex>
      <Sidebar />
      <Box
        flex={1}
        w="100%"
        h="100vh"
        bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
      >
        <Box
          p={5}
          pl={2}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          h="10.7vh"
          borderBottom="1px"
          borderColor="blackAlpha.200"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack>
            <IconButton
              icon={<ArrowBackIcon />}
              onClick={handleBackClick}
              size="md"
              bg="none"
              _hover="none"
              aria-label="Go back"
            />
            <Heading fontSize="lg" mb={0}>
              Create Sequence
            </Heading>
          </HStack>
          <Button
            size="sm"
            colorScheme="blue"
            onClick={() => {
              handleAddSequence(formData);
            }}
          >
            Save
          </Button>
        </Box>
        <Box
          as="section"
          m="2"
          h="86.5vh"
          p={4}
          pt={0}
          pb={0}
          pl={2}
          pr={2}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          borderRadius="lg"
          border="1px"
          borderColor="blackAlpha.200"
        >
          <Flex direction={{ base: "column", md: "row" }} w="100%" h="86.5vh">
            <TemplatePanel
              formData={formData}
              setFormData={setFormData}
              dynamicVariables={dynamicVariables}
              handleVariableClick={handleVariableClick}
              campaignId={campaignId}
              setCampaigns={setCampaigns}
              sequenceCount={sequenceCount}
              processTemplate={processTemplate}
              ref={templateEditorRef}
            />

            {/* Left Panel: Template and Email Preview */}
            <SequenceDetailsPanel
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              handleCheckboxChange={handleCheckboxChange}
              handleMeetingCheckboxChange={handleMeetingCheckboxChange}
              delayNumber={delayNumber}
              setDelayNumber={setDelayNumber}
              delayUnit={delayUnit}
              setDelayUnit={setDelayUnit}
            />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default SequenceFormBox;
