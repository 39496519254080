import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  MenuItem,
  TextField,
  Typography,
  Skeleton,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { styled } from "@mui/material/styles";
import axiosInstance from "../../../helpers/axiosInstance";

const FilterSection = styled(Box)(({ theme }) => ({
  backgroundColor: "#f9f9f9",
  padding: "15px", // Slightly larger padding for better spacing
  borderRadius: "8px",
  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column", // Stack content vertically
  gap: "10px",
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.secondary.light,
  color: "white",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  borderRadius: "20px",
  padding: "4px 12px",
}));

const Filters = ({ filters, onFilterChange, onClearFilters }) => {
  const [filterOptions, setFilterOptions] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchFilterOptions = async (updatedFilters) => {
    const payload = {
      filters: Object.keys(updatedFilters).reduce((acc, key) => {
        if (updatedFilters[key]) acc[key] = updatedFilters[key]; // Only include active filters
        return acc;
      }, {}),
    };

    setLoading(true); // Show loader while fetching
    try {
      const response = await axiosInstance.post(
        "/lead/filter-options",
        payload
      );
      if (response.data.success) {
        setFilterOptions(response.data.data);
      } else {
        console.error("Failed to fetch filter options:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching filter options:", error);
    } finally {
      setLoading(false);
    }
  };

  // Call fetchFilterOptions initially
  useEffect(() => {
    fetchFilterOptions(filters);
  }, []); // Run on mount

  // Update filters and call fetchFilterOptions
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    const updatedFilters = { ...filters, [name]: value };

    onFilterChange(event); // Update the parent component's filter state
    fetchFilterOptions(updatedFilters); // Fetch updated filter options
  };

  // Divide filters into chunks for multi-line rendering
  const filterKeys = Object.keys(filterOptions);
  const chunkSize = 4;
  const chunks = [filterKeys.slice(0, chunkSize), filterKeys.slice(chunkSize)];
  // const chunkSize = Math.ceil(filterKeys.length / 2); // Divide into two rows
  // const chunks = [filterKeys.slice(0, chunkSize), filterKeys.slice(chunkSize)];

  return (
    <FilterSection m={2} mb={0}>
      {/* First Row: Filters Header and Clear Button */}
      <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
        <FilterAltIcon color="primary" fontSize="small" />
        <Typography variant="subtitle2" fontWeight="bold">
          Filters:
        </Typography>
        {Object.keys(filters).map((key) =>
          filters[key] ? (
            <StyledChip
              key={key}
              label={`${key}: ${filters[key]}`}
              onDelete={() =>
                handleFilterChange({ target: { name: key, value: "" } })
              }
            />
          ) : null
        )}
        <StyledButton
          variant="outlined"
          color="error"
          onClick={onClearFilters}
          size="small"
        >
          <ClearAllIcon sx={{ mr: 1 }} fontSize="small" />
          Clear Filters
        </StyledButton>
      </Box>

      {/* Multi-Line Filter Dropdowns or Skeletons */}
      {loading
        ? Array.from({ length: 2 }).map((_, rowIndex) => (
            <Box
              key={rowIndex}
              display="flex"
              gap={2}
              flexWrap="wrap"
              justifyContent="left"
            >
              {Array.from({ length: 4 }).map((_, boxIndex) => (
                <Skeleton
                  key={boxIndex}
                  variant="rectangular"
                  width={250}
                  height={30}
                  sx={{ borderRadius: "4px", margin: "5px" }}
                />
              ))}
            </Box>
          ))
        : chunks.map((chunk, chunkIndex) => (
            <Box
              key={chunkIndex}
              display="flex"
              gap={2}
              flexWrap="wrap"
              justifyContent="left"
            >
              {chunk.map((key) => (
                <TextField
                  key={key}
                  select
                  label={key.replace(/_/g, " ").toUpperCase()} // Format key as label
                  name={key}
                  value={filters[key] || ""}
                  onChange={handleFilterChange} // Use the modified handler
                  size="small"
                  sx={{
                    maxWidth: "250px",
                    flex: "1 1 auto",
                  }}
                >
                  <MenuItem value="">All</MenuItem>
                  {filterOptions[key].map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              ))}
            </Box>
          ))}
    </FilterSection>
  );
};

export default Filters;
