import React, { useState } from "react";
import { Box, List, Image, Flex, IconButton } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useColorMode } from "@chakra-ui/react";
import SidebarItem from "./SidebarItem"; // Make sure to import the new component
import {
  MdHome,
  MdCampaign,
  MdUpload,
  MdCreate,
  MdMail,
  MdEmail,
  MdNewspaper,
} from "react-icons/md";
import logo from "../../assets/images/NEXUSAI_logo1_icon.png";
import openIcon from "../../assets/icons/sidebar-open.svg";
import closeIcon from "../../assets/icons/sidebar-close.svg";

function Sidebar() {
  const { colorMode } = useColorMode();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  // List of sidebar items with labels, icons, and links
  const sidebarItems = [
    { icon: MdMail, to: "/mailboxes", label: "Mailboxes" },
    { icon: MdMail, to: "/leads", label: "Leads" },
    { icon: MdUpload, to: "/upload", label: "Upload" },
    { icon: MdCreate, to: "/signature", label: "Signature" },
    { icon: MdCampaign, to: "/campaign", label: "Campaign" },
    { icon: MdEmail, to: "/emails", label: "Emails" },
    { icon: MdNewspaper, to: "#", label: "Newsletter", comingSoon: true },
  ];

  return (
    <Box
      width={isSidebarOpen ? "15%" : "50px"}
      h="100vh"
      bg={colorMode === "dark" ? "gray.800" : "white"}
      color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
      borderRight="1px"
      borderColor="blackAlpha.200"
    >
      <Flex
        justifyContent={isSidebarOpen ? "space-between" : "center"}
        alignItems="center"
        p="4"
        borderBottom="1px"
        borderColor="blackAlpha.200"
        h="10.7vh"
      >
        {isSidebarOpen && (
          <Link to="/home">
            <Image src={logo} height="23px" />
          </Link>
        )}
        <IconButton
          icon={
            <Image src={isSidebarOpen ? closeIcon : openIcon} height="24px" />
          }
          onClick={toggleSidebar}
          bg="white"
          aria-label="Toggle Sidebar"
          size="sm"
          _hover={{ bg: "transparent" }}
        />
      </Flex>
      {isSidebarOpen && (
        <Box p="4" pl={0} pt="8" w="100%">
          <List spacing={2} pl={5}>
            {sidebarItems.map((item, index) => (
              <Box
                key={index}
                position="relative"
                display="inline-block"
                width="100%"
              >
                <SidebarItem
                  icon={item.icon}
                  to={item.to}
                  label={item.label}
                  index={index} // Pass the index to display Roman numerals
                />
                {item.comingSoon && (
                  <Box
                    position="absolute"
                    top={-3}
                    right={0}
                    bg="#5a67d8"
                    color="white"
                    fontWeight="bold"
                    fontSize="10px"
                    px={2}
                    py={0.5}
                    borderRadius="lg"
                    display="inline"
                  >
                    Coming Soon
                  </Box>
                )}
              </Box>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
}

export default Sidebar;
