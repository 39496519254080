import axiosInstance from '../../helpers/axiosInstance';

export const fetchOrganizations = async (toast) => {
  try {
    const response = await axiosInstance.get("org/get_current_organization");
    return response; 
    // return response.data;// Assuming the API returns { success: true, data: [...] }
  } catch (error) {
    let errorMessage = "An error occurred while fetching agents.";

    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }

    console.error("Error fetching agents:", error);
    toast({
      title: "Error Message",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
    return []; // Return an empty array on error
  }
};

// Fetch all personas
export const fetchModules = async (toast) => {
  try {
    const response = await axiosInstance.get("/genie/modules");
    return response; 
    // return response.data;// Assuming the API returns { success: true, data: [...] }
  } catch (error) {
    let errorMessage = "An error occurred while fetching agents.";

    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }

    console.error("Error fetching agents:", error);
    toast({
      title: "Error Message",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
    return []; // Return an empty array on error
  }
};

export const fetchPersonas = async (toast) => {
  try {
    const response = await axiosInstance.get("/persona/get-organization-personas");
    return response; 
    // return response.data;// Assuming the API returns { success: true, data: [...] }
  } catch (error) {
    let errorMessage = "An error occurred while fetching personas.";

    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }

    console.error("Error fetching personas:", error);
    toast({
      title: "Error Message",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
    return []; // Return an empty array on error
  }
};

// Create a new persona
export const createPersona = async (persona, toast) => {
  try {
    const response = await axiosInstance.post( "/persona/create_role", persona);
    toast({
      title: "Persona created successfully",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    return response.data; // Assuming the API returns the created persona object
  } catch (error) {
    let errorMessage = "An error occurred while creating the persona.";

    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }

    console.error("Error creating persona:", error);
    toast({
      title: "Error Message",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
    return null; // Return null on error
  }
};

// Update an existing persona
export const updatePersona = async (id, persona, toast) => {
  try {
    const response = await axiosInstance.put(`/persona/${id}`, persona);
    toast({
      title: "Persona updated successfully",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    return response.data; // Assuming the API returns the updated persona object
  } catch (error) {
    let errorMessage = "An error occurred while updating the persona.";

    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }

    console.error("Error updating persona:", error);
    toast({
      title: "Error Message",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
    return null; // Return null on error
  }
};

// Delete a persona
export const deletePersona = async (id, toast) => {
  try {
    const response = await axiosInstance.delete(`/persona/${id}`);
    toast({
      title: "Persona deleted successfully",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    return response.data; // Assuming the API returns a success message or similar
  } catch (error) {
    let errorMessage = "An error occurred while deleting the persona.";

    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }

    console.error("Error deleting persona:", error);
    toast({
      title: "Error Message",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
    return null; // Return null on error
  }
};
